<template>
  <v-app>
    <v-main>
      <v-container fill-height fluid justify="center">
        <v-row
          ><v-col class="text-center">
            <div>
              <img
                src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
                alt="Logo HEIG-VD"
                height="80"
              />
            </div>
            <p class="text-body-1 mt-5">
              Le service de gestion des commandes (GesCo) est désormais
              accessible à l'adresse :
              <a href="https://gesco.heig-vd.ch">https://gesco.heig-vd.ch</a>
            </p>
            <p class="text-body-1">
              <a href="https://intra.heig-vd.ch/services/finances/bon-commande/"
                >Plus d'information dans l'intranet</a
              >
            </p>
          </v-col></v-row
        >
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>